.Nav {
    background-color: #d8f1f1;

    .nav-link {
        font-weight: bolder;
        font-size: 1.25em;
    }

    .logo-red {
        color: #e51b24;
    }

    .logo-blue {
        color: #65c8c6;
    }

    button {
        font-size: 1em;
        padding: 0;
    }

/*
background-color: #d8f1f1;
blue-color: #65c8c6;
red-color: #e51b24;
overlap-color: #5b151c;
*/

}