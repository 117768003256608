.Product {
    &.cheapest {
        background-image: linear-gradient(40deg,#45cafc,#303f9f);
        border-radius: 10px;
        color: #fff;

        .btn-link {
            color: #fff;
        }
    }
    
    .multiplier input{
        max-width: 45px;
    }

    .quantity input,
    .cost input {
        max-width: 60px;
    }

    
    @media (min-width: 576px) {
        .multiplier input{
            max-width: 80px;
        }
        
        .quantity input,
        .cost input {
            max-width: 100px;
        }
        
        .deleteProduct {
            opacity: 0.01;
        }
        &:hover .deleteProduct {
            opacity: 1;
        }
    }

}